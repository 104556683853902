import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    color: #3789D1;
    font-family: Ubuntu, Roboto, Arial, serif;
  }

  ul {
    padding-inline-start: 0;
  }

  a, a:visited {
    color: #2D72AD;
    text-decoration: none;

    :hover {
      color: #1e4b73;
    }
  }
`;